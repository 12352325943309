/* #container {
    width: 100vw;
    height: 100vh;
    background: #000;
  } 
  */
  .ion-content {

    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  /* .no-scroll {
    --overflow: hidden;
} */
::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

ion-content {
  --offset-bottom: auto!important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  }